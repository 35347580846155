












































































































import CampoDeCnpjOuCpf from "@/components/ui/CampoDeCnpjOuCpf.vue"
import DataTableDeCrud from "@/components/ui/DataTableDeCrud.vue"
import DatePicker from "@/components/ui/DatePicker.vue"
import { Voucher } from "@/models"
import { Inject } from 'inversify-props'
import { dateTimeToPtBrFormat } from "@/shareds/date/date-utils"
import { aplicarMascaraParaCpfOculto, formatarCnpjOuCpf } from "@/shareds/formatadores"
import { maiorOuIgualAZero, obrigatorio } from "@/shareds/regras-de-form"
import AlertModule from "@/store/vuex/aplicacao/AlertModule"
import { VoucherServiceAdapter } from "@/usecases"
import axios, { CancelTokenSource } from "axios"
import { Component, Ref, Vue, Watch } from "vue-property-decorator"
import { DataOptions } from "vuetify"
import moment from "moment"
import SeletorDeLojasDoUsuario from "@/components/loja/SeletorDeLojasDoUsuario.vue"
import { Loja } from "@/models"

@Component({
	components: {
		DatePicker,
		DataTableDeCrud,
		CampoDeCnpjOuCpf,
		SeletorDeLojasDoUsuario,
	},
})
export default class TelaDeManutencaoCashback extends Vue {
	@Ref() formDePeriodo!: HTMLFormElement

	dataFinal: string | null = null
	dataInicio: string | null = null
	diasParaAlteracao = 0
	dataParaAlteracao: string | null = null
	cnpjCpfDoCliente = ''

	carregando = false
	atualizandoCashback = false
	totalRegistros = -1

	listaDeCashback: Voucher[] = []
	lojas: Loja[] = []
	cashback: Voucher | null = null

	@Inject('VoucherServiceAdapter')
	private voucherService!: VoucherServiceAdapter

	cancelToken: CancelTokenSource | null = null
	lojaId: string | null = null

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	obrigatorio = obrigatorio
	maiorOuIgualAZero = maiorOuIgualAZero

	headers = [
		{ text: 'Cliente', value: 'razaoSocialOuNome' },
		{ text: 'CPF/CNPJ', value: 'cnpjOuCpfFormatado' },
		{ text: 'Gerado', value: 'dataHoraGeracao' },
		{ text: 'Vencimento', value: 'Vencimento' },
		{ text: 'Valor R$', value: 'valorCashback' },
	]

	gerarDataHora(data?: string | null, hora?: string | null): string | undefined {
		return moment(`${data} ${hora}`, 'YYYY-MM-DD HH:mm').toISOString(true)
	}

	async buscar() {
		if (this.cancelToken) this.cancelToken.cancel()
		if (!this.dataInicio || !this.dataFinal ||
			(this.dataInicio && this.dataInicio.length === 0) ||
			(this.dataFinal && this.dataFinal.length === 0))
			return

		if(!this.lojaId){
			return AlertModule.setError("É necessario selecionar uma loja!")
		}

		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()
			const params = {
				page: this.paginacao.page - 1,
				cpfOuCnpj: this.cnpjCpfDoCliente || null,
				dataInicio: this.gerarDataHora(this.dataInicio, '00:00'),
				dataFinal: this.gerarDataHora(this.dataFinal, '23:59'),
				lojaId: this.lojaId,
				tipos: ['Cashback'],
				size: this.paginacao.itemsPerPage,
				sort: 'expiracao,asc',
			}

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			
			const page = await this.voucherService.findVouchers(params, axiosConfig)
			this.totalRegistros = page.totalElements
			this.listaDeCashback = page.content
			this.carregando = false
		} catch (error) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
			this.carregando = false
		}
	}

	async alterarCashbackEmMassa() {
		this.atualizandoCashback = true
		try {
			const command = {
				cpfOuCnpj: this.cnpjCpfDoCliente || null,
				dataInicio: this.gerarDataHora(this.dataInicio, '00:00'),
				dataFinal: this.gerarDataHora(this.dataFinal, '23:59'),
				dataParaAlteracao: this.dataParaAlteracao || null,
				diasParaAlteracao: this.diasParaAlteracao || null,
				tipos: ['Cashback'],
			}

			this.voucherService.updateVoucherEmMassa(command)
			AlertModule.setInfo("Atualização em andamento. Você receberá uma notificação assim que a atualização for concluída")
			this.atualizandoCashback = false
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	get podeAlterarCashbackEmMassa() {
		if (this.atualizandoCashback ||
			(!this.dataParaAlteracao && !this.diasParaAlteracao) ||
			(
				(this.diasParaAlteracao == 0 || !this.diasParaAlteracao) &&
				(this.dataParaAlteracao && this.dataParaAlteracao.length === 0 || !this.dataParaAlteracao)
			)
		) {
			return true;
		}
		return false;
	}


	get itensFormatados() {
		return this.listaDeCashback.map(cashback => ({
			...cashback,
			cnpjOuCpfFormatado: cashback.cliente.cnpjOuCpf.length <= 11
				? aplicarMascaraParaCpfOculto(cashback.cliente.cnpjOuCpf)
				: formatarCnpjOuCpf(cashback.cliente.cnpjOuCpf),
			dataHoraGeracao: cashback.dataHoraGeracao != null ? dateTimeToPtBrFormat(cashback.dataHoraGeracao) : '- -',
			Vencimento: cashback.expiracao != null ? dateTimeToPtBrFormat(cashback.expiracao) : '- -',
			razaoSocialOuNome: cashback.cliente.razaoSocialOuNome,
			valorCashback: cashback.valor.toLocaleString('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			}),

		}))
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao = this.buscar

}
